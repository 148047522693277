<template>
  <main>
    <trac-back-button>Kyc Menu</trac-back-button>
    <div class="mt-2 text-sm font-medium">
      Complete KYC by verifying the following.
    </div>
    <form
      @submit.prevent="verifyPhone"
      class="flex justify-center flex-col bg-white rounded-md mt-12 big-shadow p-5 items-center"
    >
      <h1 class="mt-10 text-primaryBlue font-medium">
        Verify Phone Number
      </h1>
      <svg
        class="w-16 h-16 mt-5 mb-5"
        viewBox="0 0 56 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M54.0912 41.6619V49.5295C54.0972 51.0064 53.4802 52.4174 52.392 53.4158C51.3037 54.4142 49.8449 54.9076 48.374 54.7746C40.304 53.8978 32.5522 51.1402 25.7414 46.7234C19.4049 42.6969 14.0326 37.3246 10.0061 30.9881C5.57396 24.1464 2.81573 16.3569 1.9549 8.25062C1.82239 6.78428 2.31228 5.32976 3.3049 4.24237C4.29752 3.15498 5.70146 2.53485 7.17377 2.53346H15.0414C17.6741 2.50755 19.9181 4.43735 20.2865 7.04424C20.6186 9.56207 21.2344 12.0342 22.1223 14.4136C22.8428 16.3304 22.382 18.4912 20.9422 19.9472L17.6115 23.2778C21.3449 29.8435 26.7811 35.2798 33.3468 39.0131L36.6774 35.6825C38.1335 34.2427 40.2943 33.7818 42.211 34.5023C44.5904 35.3902 47.0626 36.006 49.5804 36.3381C52.2175 36.7101 54.1572 38.9995 54.0912 41.6619Z"
          stroke="#253B95"
          stroke-width="3.56667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div v-if="!confirm">
        <p class="text-center tracking-normal text-sm mt-3">
          Please confirm your phone number and an OTP will be sent to you.
        </p>

        <div class="mb-10 mt-16 max-w-md w-full">
          <trac-input
            placeholder="Phone Number"
            type="number"
            v-model="payload.reference"
            required
          />
          <!-- <div class="inline-flex mt-5">
          <svg
            class="w-4 h-4 mr-2"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99967 0.333496C4.93692 0.333496 0.833008 4.43741 0.833008 9.50016C0.833008 14.5629 4.93692 18.6668 9.99967 18.6668C15.0624 18.6668 19.1663 14.5629 19.1663 9.50016C19.1663 4.43741 15.0624 0.333496 9.99967 0.333496ZM10.9163 14.0835H9.08301V8.5835H10.9163V14.0835ZM10.9163 6.75016H9.08301V4.91683H10.9163V6.75016Z"
              fill="#979797"
            />
          </svg>
          <p class="text-xs text-primaryGray">
            To quickly retreive your BVN, kindly dial *565*0# on your <br />
            mobile device.
          </p>
        </div> -->
        </div>

        <div class="mt-8 w-full  mb-6">
          <trac-button class="w-full" type="submit"
            ><span class="px-12  text-center">Send OTP</span></trac-button
          >
        </div>
      </div>
      <div v-else>
        <p class="text-center tracking-normal text-sm mt-3">
          An OTP has been sent to you, please check your email.
        </p>
        <p class="text-center tracking-normal text-xs mt-3">
          Didn’t recieve an email? <a href=""> Click resend.</a>
        </p>
      </div>
    </form>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  GET_USER_BUSINESS_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      confirm: false,
      payload: {
        reference: "",
        verificationType: "PHONE",
        businessId: "",
      },
    };
  },

  methods: {
    async verifyPhone() {
      if (this.payload.reference === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Your Phone Number",
        });
        return;
      }
      let arr = ["080", "081", "090", "091", "070", "071"];
      let test1 = arr.some((el) => this.payload.reference.startsWith(el));

      if (!test1) {
        eventBus.$emit("trac-alert", {
          message: "Phone Number not valid",
        });
        return;
      }
      if (+this.payload.reference.length !== 11) {
        eventBus.$emit("trac-alert", {
          message: "Phone Number not valid",
        });
        return;
      }
      let res = await this.$store.dispatch("SEND_OTP", this.payload);
      if (res.status) {
        eventBus.$emit("trac-alert", {
          message: "OTP sent succesfully, kindly check your phone",
        });
        this.$router.push({ name: "VerifyPhoneOtp" });
      }
    },
  },
  mounted() {
    this.payload.businessId = GET_USER_BUSINESS_ID();
    this.payload.reference = GET_USER_BUSINESS_DATA().phone;
  },
};
</script>

<style>
.g-gray {
  background: rgba(230, 227, 227, 0.55);
}
</style>
